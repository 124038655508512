import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';



class Brand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }
  componentDidMount() {

    if(this.props.id){
    Auth.currentSession()
      .then(res => {
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }        
        }
        fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand/'+this.props.id+'/'+this.props.identifier, request_objects)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({ loading: false });
          this.setState({data:{
            name:data.name,
            description:data.description,
            identifier:data.identifier,
            logo:data.logo,
            id:data.id}})
            })
        .catch(err => {
          //if there is an error/cors issue returned, the pin is incorrect
          console.error('Pairing has failed ', err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
      });
   }
   
  }

  render() {
    return (
      <div className="row">
        <div className="col">
                      <h1 class="mt-0">{this.state.data.name} <span class="badge badge-primary">{this.state.data.identifier}</span></h1>

          <div class="media">
            <img src={this.state.data.logo} class="mr-3" alt={this.state.data.name+" logo"} width="300"/>
            <div class="media-body">
              <p>{this.state.data.description}</p>
            </div>
          </div>
          <div class="btn-group mt-2" role="group" aria-label="First group">
            <Button type="button" class="btn btn-secondary" href={"/brand/"+this.state.data.id+"/"+this.state.data.identifier+"/edit"}>Edit brand</Button>
            <Button type="button" class="btn btn-secondary" href={"/brand/"+this.state.data.id+"/"+this.state.data.identifier+"/configuration"}>Edit configuration</Button>
            <Button type="button" class="btn btn-secondary" disabled>Archive brand</Button>
            <Button type="button" class="btn btn-secondary" disabled>Clone brand</Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Brand;
