import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';
import 'react-datepicker/dist/react-datepicker.css';
import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button,Modal} from 'react-bootstrap';
import * as moment from 'moment'

import DatePicker from 'react-datepicker'
class NewVoucher extends React.Component {
  
  
  constructor(props) {
    super(props);
    this.state = {
      modal:false,
      returned_object:{},
      brand_id:null,
      env:process.env.REACT_APP_AAA_ENV,
      reference:null,
      tags:[],
      expires_on:moment.apply(new Date()).add(30,'days').format("YYYY-MM-DD") + 'T23:59Z',
      selected_brand_envs:[],
      endpoint:'',
      brands:[
        {
          name:"CNPLUS_LATAM",envs:[
            {name:"dev",endpoint:"https://voucher-auth.dev.aaa.wmgp.io/v1/code"},
            {name:"stg",endpoint:"https://voucher-auth.stg.aaa.wmgp.io/v1/code"},
            {name:"prd",endpoint:"https://voucher-auth.prd.aaa.wmgp.io/v1/code"}
          ]
        },
        {
          name:"SPORTS_LATAM",
          envs:[
            {name:"dev",endpoint:"https://voucher-auth.dev.aaa.wmgp.io/v1/code"},
            {name:"stg",endpoint:"https://voucher-auth.stg.aaa.wmgp.io/v1/code"},
            {name:"prd",endpoint:"https://voucher-auth.prd.aaa.wmgp.io/v1/code"}
          ]
        }
      ],
      
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.setStartDate = this.setStartDate.bind(this);
  }
  componentDidMount() {
    
  }
  
  handleClose(){
    this.setState({modal:false})
  }
  setStartDate(date){
    console.log(date);
  }

  handleSubmit(event){
    event.preventDefault();
    this.setState({ loading: true });
    Auth.currentSession()
    .then(res => {
      //extract JWT to use as auth header
      console.log({brand_id:this.state.brand_id ,
        env:this.state.env ,
        reference:this.state.reference ,
        tags:this.state.tags,
        expires_on:this.state.expires_on})
        let idToken = res.getIdToken().getJwtToken();
        
        let request_objects = {
          method: 'POST',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          },         
          body: JSON.stringify({
            brand_id:this.state.brand_id ,
            reference:this.state.reference ,
            tags:this.state.tags,
            env:this.state.env,
            expires_on:this.state.expires_on 
          })
        }
        fetch(process.env.REACT_APP_VOUCHER_ENDPOINT, request_objects)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({returned_object:data})
          this.setState({ loading: false, modal: true });
        })
        .catch(err => {
          console.error( err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
        
        
      })
    }
    
    render() {
      
      return (
        <div>
          <div className="row bg-lighter p-3 mb-dark">
            <div className="col">
              <h2>Create New Code</h2>
            </div>
          </div>
          <Form onSubmit={this.handleSubmit}>
            <div className="row bg-lighter mb-3 p-3">
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label>Brand</Form.Label>
                  <Form.Control as="select" required  onChange={e => this.setState({ brand_id: this.state.brands[e.target.value].name, selected_brand_envs:this.state.brands[e.target.value].envs })}>
                  <option value="" key="select" disabled selected hidden>Select...</option>
                  {this.state.brands.map((value, index) => {
                    return (
                      <option value={index} key={index+"brand"}>{value.name}</option>
                      )
                    })
                  }
                  </Form.Control>
                  <Form.Control.Feedback type="invalid">
                  Please provide a brand.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group>
                  <Form.Label>Reference</Form.Label>
                  <Form.Control type="text" required onChange={e => this.setState({ reference: e.target.value })}/>
                  <Form.Control.Feedback type="invalid">
                  Please provide a reference.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-3">
                <Form.Group>
                  <Form.Label>Tags</Form.Label>
                  <Form.Control type="text" placeholder="example: unique_identifier_string" required  onChange={e => this.setState({ tags: e.target.value.split(',') })}/>
                  <Form.Control.Feedback type="invalid">
                  Please provide a valid identifier.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label>Expiry date</Form.Label>
                  <Form.Control type="date" required value={moment.apply(new Date()).add(30,'days').format("YYYY-MM-DD")} onChange={e => this.setState({ expires_on: e.target.value + 'T23:59Z' })}/>
                  <Form.Control.Feedback type="invalid">
                  Please provide a valid date.
                  </Form.Control.Feedback>
                </Form.Group>
              </div>
              <div className="col-md-2">
                <Form.Group>
                  <Form.Label> </Form.Label><br/>
                  <Button type="submit" className="mt-2">CREATE CODE</Button>
                </Form.Group>
              </div>
            </div>
          </Form>
          <Modal show={this.state.modal} onHide={this.handleClose} centered className="dark">
          <Modal.Header closeButton>
          <Modal.Title>Voucher code created</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <code>{this.state.returned_object.value}  <span className="badge badge-primary btn" onClick={()=>{
            navigator.clipboard.writeText(this.state.returned_object.value).then(function() {
              console.log('Async: Copying to clipboard was successful!');
            }, function(err) {
              console.error('Async: Could not copy text: ', err);
            });
          }}>copy code</span></code>
          </Modal.Body>
          <Modal.Footer>
          <Button variant="secondary" onClick={this.handleClose}>
          Close and create another
          </Button>
          <Button variant="primary" onClick={()=>window.location.assign("/vouchers")}>
          Back to voucher dashboard
          </Button>
          </Modal.Footer>
          </Modal>
        </div>
        );
      }
      
    }
    export default NewVoucher;
    