import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';

const config = JSON.parse(localStorage.getItem('aaa_config'));

class Loading extends React.Component {
	render() {
		if(this.props.state){
			return (
				<div className="loader">
					<div className="d-flex justify-content-center spinner">
					  <div className="spinner-border" role="status">
					    <span className="sr-only">Loading...</span>
					  </div>
					  <div className="message"><p>{this.props.message}</p></div>
					</div>
				</div>
			)
		}else{
			return null
		}
		
	}
}

export default Loading