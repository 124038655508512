import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';



class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };

  }
  componentDidMount() {
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
        method: 'GET',
        headers: {
          Accept: '*/*',
          'Content-Type': 'application/json',
          Authorization: 'Bearer ' + idToken
        }
      }
    });
  }

  handleSubmit(event){
    var form = event.currentTarget;
    console.log(form);
  }
  deleteBrand(id){
    console.log(id);
  }

  render() {
    return (
      <div>
        <div className="row">
           <h1>Welcome to the operations control panel</h1>
        </div>

        <div className="row bg-lighter p-3 mb-dark">
          <div className="col">
            <p>This control panel will eventually give you access to all operational processes in an easy to digest format.</p>
            <p>Here you will be able create brands, manage configuration for; AAA (access, authentication and authorisation), live streams, blackouts, etc..</p>
          </div>
        </div>
        
        <div className="row mt-3">
          <div className="col">

            <Button type="submit" href="/vouchers">Manage and create guest login vouchers</Button>
      
          </div>
        </div>
      </div>
    );
  }
}
export default Home;
