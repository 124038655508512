import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';



class BrandConfiguration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      aaa:null,
      environments:[]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.configureAAA = this.configureAAA.bind(this);
  }
  componentDidMount() {
   if(this.props.id){
    Auth.currentSession()
      .then(res => {
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }        
        }
        fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand/'+this.props.id+'/'+this.props.identifier, request_objects)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({ loading: false });
          this.setState({data:{
            name:data.name,
            description:data.description,
            identifier:data.identifier,
            logo:data.logo,
            id:data.id}
              })
            })
        .catch(err => {
          //if there is an error/cors issue returned, the pin is incorrect
          console.error('Pairing has failed ', err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
      });
   }
  }

  configureAAA(event){
    console.log(this.state.environments)
    if(this.state.environments.length > 0){
      this.setState({
        aaa:{
          cognito: {
            identityPoolRegion: 'eu-west-1',
            userPoolId: 'eu-west-1_5OjCb5jgz',
            userPoolWebClientId: '40lduhltl4s4vg9a41hrvkgs9u',
            mandatorySignIn: false,
            federatedSignIn: {
              provider: 'WMID'
            },
            oauth: {
              domain: 'aaa-waves-dev.auth.eu-west-1.amazoncognito.com',
              scope: ['openid'],
              redirectSignIn: 'http://localhost:3000',
              redirectSignOut: 'http://localhost:3000',
              provider: 'WMID',
              responseType: 'code'
            },
            federationTarget: 'COGNITO_USER_POOLS'
          }
        }
      })
    }
  }
  handleSubmit(event){
    var form = event.currentTarget;
    console.log(form);
  }
  handleEnvironments(e){
    var options = e.target.options;
    var value = [];
    for (var i = 0, l = options.length; i < l; i++) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    this.setState({environments:value});
  }

  render() {
      return (
        <div className="row">
          <div className="col">
          <h1>Edit brand {this.state.data.name}</h1>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>Unique identifier</Form.Label>
                <Form.Control type="text" placeholder="unique_identifier_string" disabled value={this.state.data.identifier} />
                <Form.Control.Feedback type="invalid">
                  Please provide a valid identifier.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                {(() => {
                  if (this.state.aaa) {
                    return(
                      <Form.Group className="border p-3">

                        <h3>Cognito pool details</h3>
                        <hr />
                        {this.state.environments.map((value, index) => {
                          return(
                          <Form.Group className="border bg-info p-3" key={index}>
                            <h4>{value}</h4>
                            <Form.Label>Identity Pool Region</Form.Label>
                            <Form.Control type="text" disabled value={this.state.aaa.cognito.identityPoolRegion} />
       
                            <Form.Label>User Pool ID</Form.Label>
                            <Form.Control type="text" disabled value={this.state.aaa.cognito.userPoolId} />

                            <Form.Label>Web Client ID</Form.Label>
                            <Form.Control type="text" disabled value={this.state.aaa.cognito.userPoolWebClientId} />
                          </Form.Group>
                          )
                        })}
                      

                      </Form.Group>
                    )
                  }else{
                    return(
                      <Form.Group controlId="exampleForm.ControlSelect2" className="border p-3">
                        <Form.Label>Select environments needed for Cognito</Form.Label>
                        <Form.Control as="select" multiple onChange={e => this.handleEnvironments(e)}>
                          <option>Development</option>
                          <option>Testing</option>
                          <option>Pre-Production</option>
                          <option>Production</option>
                        </Form.Control>
                        <Button className="btn-secondary mt-3" onClick={() => this.configureAAA()}>Configure AAA for {this.state.data.name}</Button>
                      </Form.Group>

                    )
                  }
                })()}           
              </Form.Group>

              <Button type="submit">Update {this.state.data.name} configuration</Button>
            </Form>
          </div>
        </div>
      );
  }
}
export default BrandConfiguration;
