import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';



class Brands extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      brands:[]
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.deleteBrand = this.deleteBrand.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }

  loadData(){
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        }
      fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand', request_objects)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({ brands: data });
        this.setState({ loading: false });
      })
      .catch(err => {
        console.error( err);
        this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
        this.setState({ loading: false });
      });
    });
  }

  handleSubmit(event){
    var form = event.currentTarget;
    console.log(form);
  }
  deleteBrand(id,identifier){
    console.log(id);
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'DELETE',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        }
        fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand/'+id+'/'+identifier, request_objects)
        //.then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({ loading: false });
          this.loadData();
        })
        .catch(err => {
          //if there is an error/cors issue returned, the pin is incorrect
          console.error('Pairing has failed ', err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
       
    })
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
           <h1>Brands available</h1>
           <hr />
          </div>
          
        </div>
        
        {this.state.brands.map((value, index) => {
            return (

              <div className="row" key={index}>
                <div className="col">
                  <div className="media">
                    <img src={value.logo} className="mr-3" alt={value.name+" logo"} width="100"/>
                    <div className="media-body">
                      <h5 className="mt-0">{value.name} <span className="badge badge-primary"> {value.identifier}</span></h5>
                      <p>{value.description}</p>
                    </div>
                  </div>
                  <div className="btn-group mr-2" role="group" aria-label="First group">
                    <Button type="button" className="btn btn-secondary" href={"/brand/"+value.id+"/"+value.identifier}>View brand details</Button>
                    <Button type="button" className="btn btn-secondary" href={"/brand/"+value.id+"/"+value.identifier+"/edit"}>Edit brand</Button>
                    <Button type="button" className="btn btn-secondary" href={"/brand/"+value.id+"/"+value.identifier+"/configuration"}>Edit configuration</Button>
                    <Button type="button" className="btn btn-secondary" onClick={() => this.deleteBrand(value.id,value.identifier)}>Delete brand</Button>
                    <Button type="button" className="btn btn-secondary" disabled>Clone brand</Button>
                  </div>
                   <hr />
                </div>

              </div>
            )
          })
        }
        
        <div className="row">
          <div className="col">
          
           <Button type="submit" href="/newbrand">Create new brand</Button>
          </div>
        </div>
      </div>
    );
  }
}
export default Brands;
