import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Loading from '../../loading.js'
import NewVoucher from './new_voucher.js'
                            

class Vouchers extends React.Component {
    
    columns = [
        {
          name: 'CREATED BY',
          selector: 'user_id',
          sortable: true,
        },
        {
          name: 'BRAND',
          selector: 'brand_id',
          sortable: true,
          right: true,
        },
          {
            name: 'EXPIRES',
            selector: 'expires_on',
            sortable: true,
            right: true,
            format:row => new Date(row.expires_on*1000).toLocaleDateString()
          },
          {
            name: 'REFERENCE',
            selector: 'reference',
            sortable: true,
            right: true,
          },
          {
            name: 'TAGS',
            selector: 'tags',
            sortable: true,
            right: true,
          },
          {
            name: 'VOUCHER CODE',
            selector: 'value',
            sortable: true,
            right: true,
            cell: row => <div className='copy' onClick={()=>{
              navigator.clipboard.writeText(row.value).then(function() {
                console.log('Async: Copying to clipboard was successful!');
              }, function(err) {
                console.error('Async: Could not copy text: ', err);
              });
            }}><span>{row.value}</span></div>
          },
          {
            name: 'STATUS',
            selector: 'status',
            sortable: true,
            right: true,
            width:'70px',
            cell: row => <div className={'status-'+row.status}>{row.status}</div>
          },
          {
            name: '',
            sortable: true,
            width:"22px",
            cell: row => <button type="button" className="close" onClick={()=>this.expireToken(row.value)}><span aria-hidden="true">&times;</span></button>,
          },
      ];

      
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      vouchers:[],
      loading:true,
      loadingMessage:'Loading vouchers'
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.expireToken = this.expireToken.bind(this);
    this.loadData = this.loadData.bind(this);
  }
  componentDidMount() {
    this.loadData();
  }

  loadData(){
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        }

        //i need to add a chained promise in here to fetch data from all environments defined in App.js
        console.log(process.env.REACT_APP_AAA_ENV)
        console.log('env var= '+process.env.REACT_APP_VOUCHER_ENDPOINT)
      fetch(process.env.REACT_APP_VOUCHER_ENDPOINT, request_objects)
      .then(response => response.json())
      .then(data => {
        console.log(data);
        this.setState({ vouchers: data });
        this.setState({ loading: false });
      })
      .catch(err => {
        console.error( err);
        this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
        this.setState({ loading: false });
      });
    });
  }

  handleSubmit(event){
    var form = event.currentTarget;
    console.log(form);
  }
 
  expireToken(id){
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'DELETE',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }
        }
      fetch(process.env.REACT_APP_VOUCHER_ENDPOINT+'?code_value='+id, request_objects)
      //.then(response => response.json())
      .then(data => {
        console.log(data);
        this.loadData();
        this.setState({ loading: false });
      })
      .catch(err => {
        console.error( err);
        this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
        this.setState({ loading: false });
      });
    });
  }

  render() {
    return (
      <div>
        <Loading
          state={this.state.loading}
          message={this.state.loadingMessage}
          locale={this.props.locale}
        />
        
        <div className="row mb-3">
           <h1>Guest Logins</h1>
        </div>

        <div className="row bg-lighter p-3 mb-3">
          <div className="col">
           <p>Access codes are unique alphanumeric codes distributed to approved WarnerMedia Guests to allow temporary access to a brand environment from the login screen.</p>
          </div>
          
        </div>
        
        < NewVoucher />
        <div className="row bg-lighter p-3 mb-dark">
        <div className="col">
        <h2>Voucher Codes</h2>
        </div>
        </div>
        <div className="row bg-lighter mb-3 p-3">
        <div className="col">
                <DataTable
                keyField='expires_on'
                    data={this.state.vouchers}
                    columns={this.columns}
                    pagination={true}
                    striped={true}
                />

            </div>
        </div>
        
        
      </div>
    );
  }
}
export default Vouchers;
