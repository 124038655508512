import 'bootstrap/dist/css/bootstrap.css';
import './index.scss';
import React from 'react';

import {Navbar, Nav, NavDropdown, Button, Modal} from 'react-bootstrap';

import { mount, route } from 'navi';
import { Router, View, Link, useCurrentRoute } from 'react-navi';

import { Authenticator,withOAuth } from 'aws-amplify-react'; 
import Amplify, { Hub, Auth } from 'aws-amplify';

import EditBrand from './sections/brands/edit_brand'
import Brands from './sections/brands/brands'
import Brand from './sections/brands/brand'
import BrandConfiguration from './sections/brands/brand_config'

import Vouchers from './sections/vouchers/vouchers'
import NewVoucher from './sections/vouchers/new_voucher'

import Home from './home'

//if(window.location.host === 'fe.dev.console.wmgp.io'){
  var returnAddress = window.location.origin;
//}else{
//  var returnAddress = "http://localhost:3000";
//}

window.base_url = 'https://voucher-auth.dev.aaa.wmgp.io/v1/code';
window.envs = [
  {name:"dev",endpoint:"https://voucher-auth.dev.aaa.wmgp.io/v1/code"},
  {name:"stg",endpoint:"https://voucher-auth.stg.aaa.wmgp.io/v1/code"},
  {name:"prd",endpoint:"https://voucher-auth.prd.aaa.wmgp.io/v1/code"}
]

console.log('env: '+process.env.REACT_APP_AAA_ENV);

Amplify.configure({
  Auth: {
    "identityPoolRegion": "eu-west-1",
    "userPoolId": "eu-west-1_JugGB1C8Q",
    "userPoolWebClientId": "2amej34p13mlk1s5dsdfbmfsrl",
    "mandatorySignIn": false,
    "federatedSignIn": {
        "provider": "WMOKTA"
    },
    "oauth": {
        "domain": "wmgp-ops-console.auth.eu-west-1.amazoncognito.com",
        "scope": [
            "openid"
        ],
        "redirectSignIn": returnAddress,
        "redirectSignOut": returnAddress,
        "provider": "WMOKTA",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS"
  }
});

class App extends React.Component {
  
  constructor(props) {
    super(props);
    
    this.state = {
      language:'en',
      idToken:null,
      modal:false,
      user:{},
      currentRoute:window.location.pathname
    }
    this.handleLanguageChange = this.handleLanguageChange.bind(this);
    this.signOut = this.signOut.bind(this);
    this.handleClose = this.handleClose.bind(this);
    this.handleOpen = this.handleOpen.bind(this);
    this.activeLink = this.activeLink.bind(this);
    
  }

  activeLink(e){
    if(e == this.state.currentRoute){
      return 'active'
    }else{
      return ''
    }
  }

  handleLanguageChange(e){
    console.log(e.target.value);
    window.config.locales.default = e.target.value;
    this.setState({language:e.target.value})
  }

  signOut(){
    Auth.signOut()
    .then(data => console.log(data))
    .catch(err => console.log(err));
    this.setState({ idToken: null});
  }

  componentWillMount(){

    this.routes = mount({     
      '/': route(req => {
        return {
          view: <Home locale={this.state.language}  />
        };
      }),
      '/vouchers': route(req => {
        return {
          view: <Vouchers locale={this.state.language}  />
        };
      }),
      '/newvoucher': route(req => {
        return {
          view: <NewVoucher locale={this.state.language}  />
        };
      }),
      '/newbrand': route(req => {
        return {
          view: <EditBrand locale={this.state.language}  />
        };
      }),
      '/brands': route(req => {
        return {
          view: <Brands locale={this.state.language}  />
        };
      }),
      '/brand/:id/:identifier': route(req => {
        //stream is vod or live
        let id = req.params.id;
        let identifier = req.params.identifier;
        return {
          view: <Brand locale={this.state.language}  id={id} identifier={identifier} />
        };
      }),
      '/brand/:id/:identifier/edit': route(req => {
        //stream is vod or live
        let id = req.params.id;
        let identifier = req.params.identifier;
        return {
          view: <EditBrand locale={this.state.language}  id={id} identifier={identifier} />
        };
      }),
      '/brand/:id/:identifier/configuration': route(req => {
        //stream is vod or live
        let id = req.params.id;
        let identifier = req.params.identifier;
        return {
          view: <BrandConfiguration locale={this.state.language}  id={id} identifier={identifier} />
        };
      }),
    });
  }
  componentDidUpdate(){
    console.log(this.state.language);

  }
  componentDidMount() {

    Auth.currentSession()
      .then(res => {
        console.log('loggedIn',res)
        //extract JWT to use as auth header
        this.setState({ idToken: res.getIdToken().getJwtToken(),user:res.idToken.payload});
    });
    Hub.listen('auth', data => {
      const { payload } = data;
      console.log('A new auth event has happened: ', data);
      if (payload.event === 'signIn') {
        Auth.currentSession()
          .then(res => {
            console.log('loggedIn',res)
            //extract JWT to use as auth header
            this.setState({ idToken: res.getIdToken().getJwtToken()});
        }); 
        //store payload in user state
        console.log('Sign in');
        Auth.currentAuthenticatedUser().then(
          user => {

            const { attributes } = user;
            console.log(attributes)
          });
      }
    })
  }

  handleClose(){
    this.setState({modal:false})
  }
  handleOpen(){
    this.setState({modal:true})
  }

  render(){
    if(this.state.idToken){
      return (
        <div>
          
          <div className={process.env.REACT_APP_AAA_ENV +'_nav'} onClick={this.handleOpen}>{process.env.REACT_APP_AAA_ENV }</div>
          <Navbar bg="dark" expand="lg" variant="dark">
            <Navbar.Brand href="/"></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav"/>
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="ml-auto">

                

                <NavDropdown title={this.state.user.email} id="basic-nav-dropdown">
                  <NavDropdown.Item onClick={this.signOut}>Sign out</NavDropdown.Item>
                </NavDropdown>
                

              </Nav>
            </Navbar.Collapse>
          </Navbar>
          
          <div className="App container-fluid">
            <div className="row App">
              <div className="col-md-2 bg-dark m-0 p-0">
                <a href={"/"} className={"side-nav "+this.activeLink("/")}>Dashboard</a>
                <a href={"/vouchers"} className={"side-nav "+this.activeLink("/vouchers")}>Guest Logins</a>
              </div>
              <div className="col-md-10 p-5">
                <Router routes={this.routes} locale={this.state.language}>
                  <View/>
                </Router>
              </div>
              
            </div>
          </div>
          <Modal show={this.state.modal} onHide={this.handleClose} centered className="dark">
            <Modal.Header closeButton>
              <Modal.Title>Select environment</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <a href="https://fe.dev.console.wmgp.io/"><Button className="env-btn">Development</Button></a>
              <a href="https://fe.stg.console.wmgp.io/"><Button className="env-btn">Staging</Button></a>
              <a href="https://fe.prd.console.wmgp.io/"><Button className="env-btn">Production</Button></a>
            </Modal.Body>
          </Modal>


        </div>

        
      );
    }else{
      return (
        <div>
          <Navbar bg="dark" expand="lg">
            <Navbar.Brand href="/"></Navbar.Brand>
            <Navbar.Toggle aria-controls="basic-navbar-nav" />
            <Navbar.Collapse id="basic-navbar-nav">
              <Nav className="mr-auto">
                <Button
                onClick={() => Auth.federatedSignIn({ provider: 'WMUser' })}
                >Login</Button>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div className="App container pt-3">
            <h1>You need to be logged in to view this content</h1>
            
          </div>
        </div>
      );
    }
  }
}

export default App;
