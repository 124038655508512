import 'bootstrap/dist/css/bootstrap.css';
import 'index.scss';

import React from 'react';
import { Auth } from 'aws-amplify';
import {Form,Button} from 'react-bootstrap';

class EditBrand extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name:null,
        description:null,
        identifier:null,
        logo:null,
        id:null
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
   if(this.props.id){
    Auth.currentSession()
      .then(res => {
        let idToken = res.getIdToken().getJwtToken();
        let request_objects = {
          method: 'GET',
          headers: {
            Accept: '*/*',
            'Content-Type': 'application/json',
            Authorization: 'Bearer ' + idToken
          }        
        }
        fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand/'+this.props.id+'/'+this.props.identifier, request_objects)
        .then(response => response.json())
        .then(data => {
          console.log(data);
          this.setState({ loading: false });
          this.setState({
            name:data.name,
            description:data.description,
            identifier:data.identifier,
            logo:data.logo,
            id:data.id})
            })
        .catch(err => {
          //if there is an error/cors issue returned, the pin is incorrect
          console.error('Pairing has failed ', err);
          this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
          this.setState({ loading: false });
        });
      });
   }
  }

  handleSubmit(event){
    event.preventDefault();
    console.log(this.state);
    this.setState({ loading: true });
    Auth.currentSession()
      .then(res => {
        //extract JWT to use as auth header
        let idToken = res.getIdToken().getJwtToken();
        if(this.props.id){
          let request_objects = {
            method: 'PUT',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + idToken
            },          
            body: JSON.stringify({
              name:this.state.name,
              description:this.state.description,
              identifier:this.state.identifier,
              logo:this.state.logo,
              id:this.state.id
            })
          }
          fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand', request_objects)
          //.then(response => response.json())
          .then(data => {
            console.log(data);
            this.setState({ loading: false });
          })
          .catch(err => {
            //if there is an error/cors issue returned, the pin is incorrect
            console.error('Pairing has failed ', err);
            this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
            this.setState({ loading: false });
          });
        }else{
          let request_objects = {
            method: 'POST',
            headers: {
              Accept: '*/*',
              'Content-Type': 'application/json',
              Authorization: 'Bearer ' + idToken
            },          
            body: JSON.stringify({
              name:this.state.name,
              description:this.state.description,
              identifier:this.state.identifier,
              logo:this.state.logo
            })
          }
          fetch('https://5gyaalbgf5.execute-api.eu-west-1.amazonaws.com/api/brand', request_objects)
          //.then(response => response.json())
          .then(data => {
            console.log(data);
            this.setState({ loading: false });
          })
          .catch(err => {
            console.error( err);
            this.setState({success:'Sorry, there has been an issue, please generate a new pin on your TV and try again'})
            this.setState({ loading: false });
          });
        }

    })
  }

  render() {
    if(this.props.id){
      return (
        <div className="row">
          <div className="col">
          <h1>Edit brand</h1>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group  role="form">
                <Form.Label>Brand name</Form.Label>
                <Form.Control type="text" required value={this.state.name} onChange={e => this.setState({ name: e.target.value })}/>
                <Form.Control.Feedback type="invalid">
                  Please provide a brand name.
                </Form.Control.Feedback>
              </Form.Group>
            
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows="3" value={this.state.description} onChange={e => this.setState({ description: e.target.value })} />
              </Form.Group>

              <Form.Group>
                <Form.Label>Logo</Form.Label>

                <Form.Control type="text" required value={this.state.logo}  onChange={e => this.setState({ logo: e.target.value })}/>

                <br /><br />
                <img src={this.state.logo} width="50%"/>
                <Form.Control.Feedback type="invalid">
                  Please provide a logo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Unique identifier</Form.Label>
                <Form.Control type="text" placeholder="unique_identifier_string" required value={this.state.identifier} disabled/>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid identifier.
                </Form.Control.Feedback>
              </Form.Group>

              <Button type="submit">Update Brand</Button>
            </Form>
          </div>
        </div>
      );
    }else{
      return (
        <div className="row">
          <div className="col">
          <h1>Create new brand</h1>
            <Form onSubmit={this.handleSubmit}>
              <Form.Group>
                <Form.Label>Brand name</Form.Label>
                <Form.Control type="text" required  onChange={e => this.setState({ name: e.target.value })}/>
                <Form.Control.Feedback type="invalid">
                  Please provide a brand name.
                </Form.Control.Feedback>
              </Form.Group>
            
              <Form.Group>
                <Form.Label>Description</Form.Label>
                <Form.Control as="textarea" rows="3"  onChange={e => this.setState({ description: e.target.value })}/>
              </Form.Group>

              <Form.Group>
                <Form.Label>Logo</Form.Label>
                <Form.Control type="text" required placeholder="example: https://yourdomain.com/images/logo.png"  onChange={e => this.setState({ logo: e.target.value })}/>
                <Form.Control.Feedback type="invalid">
                  Please provide a logo.
                </Form.Control.Feedback>
              </Form.Group>

              <Form.Group>
                <Form.Label>Unique identifier</Form.Label>
                <Form.Control type="text" placeholder="example: unique_identifier_string" required  onChange={e => this.setState({ identifier: e.target.value })}/>
                <Form.Control.Feedback type="invalid">
                  Please provide a valid identifier.
                </Form.Control.Feedback>
              </Form.Group>

              <Button type="submit">Create Brand</Button>
            </Form>
          </div>
        </div>
      );
    }
  }
}
export default EditBrand;
